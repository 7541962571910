<template>
  <div class="">
    <header class="text-left">
    
      <div class="row justify-content-center px-3 justify-content-md-start align-items-center" style="gap:15px">
        <ElthModal ref="elthModal">
          <template #header>
            <div class="modal-heading" >{{translateCustomText("Do you really want to leave?")}}</div>
          </template>
           <template #default>
            <div>
              <div class="modal-body p-0">
                {{translateCustomText("You have unsaved changes. Exiting now could result in data lost. Please consider saving first.")}}
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-center justify-content-md-end w-100" style="gap:7px">
                  <ElthButton @click="()=>{toggleStoreToAddMore();$refs.elthModal.close()}" class="modal-button" style="color: #2A1C1E; background:#ffff;">{{translateCustomText("Leave")}}</ElthButton>
                  <ElthButton @click="$refs.elthModal.close()" class="modal-button" style="background: #2A1C1E; color:#ffff;">{{translateCustomText("Stay")}}</ElthButton>
                </div>
              </div>
            </div>
          </template>
        </ElthModal>
        <div class="elth-header">
          {{ translateCustomText("MANAGE YOUR PRODUCTS") }}
        </div>
     <div class="gradient-border">
<!-- ()=>{} -->
<!-- ()=>{filter_selection='add more'; selectedProductsArray=[];isSelectedAll=false;selectFilter=false;} -->
       <div class="d-flex " style="border-radius: 30px; overflow: hidden; background: #f7f0ed;">
         <button @click="checkToggleProduct('shop')"  class="px-3 py-1" :class="[filter_selection=='shop'?'active':'']">{{translateCustomText("Shop products")}}</button>
         <button @click="checkToggleProduct('add more')"   class="px-3 py-1"  :class="[filter_selection=='add more'?'active':'']" >{{translateCustomText("Add more")}}</button>
       </div>
     </div>
      </div>
      <!-- <div class="elth-description">
        {{
          translateCustomText(
            "Select the products below to be shown in your shop"
          )
        }}
      </div> -->
    </header>
    <section class="filter my-3">
      <b-row>
        <b-col cols="12" md="6" class="pr-md-1">
          <input
            type="text"
            :name="`elth-input-control-${Date.now()}`"
            :id="`elth-input-control-${Date.now()}`"
            :placeholder="
              translateCustomText(`Search by product title or brand name`)
            "
            v-model.trim="filter_text"
            class="control-input px-3 py-2" />
        </b-col>

        <b-col cols="5" md="2" class="px-md-1 my-2 mt-md-0">
          <select
            @change="filterMethod()"
            v-model="selectedVendor"
            class="control-input px-3 py-2">
            <option class="elth-select-option" value="">
              <label>{{ translateCustomText("Brand") }}</label>
            </option>
            <option
              class="elth-select-option"
              :value="vendor"
              v-for="(vendor, index) in getVendorTypes.brand"
              :key="vendor + index">
              {{ vendor }}
            </option>
          </select>
          <i class="fa fa-chevron-down"></i>
        </b-col>
        <b-col cols="7" md="2" class="px-md-1 my-2 mt-md-0"
          ><select
            @change="filterMethod()"
            v-model="selectedType"
            class="control-input px-3 py-2">
            <option class="elth-select-option" value="">
              <label>{{ translateCustomText("Type of product") }}</label>
            </option>
            <!-- <b-form-select-option  class="elth-select-option" value=""><label>{{translateCustomText( 'All')}}</label></b-form-select-option> -->
            <option
              class=""
              :value="ProductType"
              v-for="(ProductType, index) in getVendorTypes.type"
              :key="ProductType + index">
              {{ ProductType }}
            </option>
          </select>
          <i class="fa fa-chevron-down"></i>
        </b-col>
        <b-col cols="2" class="text-center d-flex align-items-center">
          <div class="d-flex align-items-center h-100 justify-content-end">
            <span
              class="product-specs-heading text-center clear-btn"
              @click="resetFilter"
              >{{ translateCustomText("Reset filters") }}</span
            >
          </div>
        </b-col>
      </b-row>
      <!-- <b-row class="d-none d-md-flex mt-2"> -->

      <!-- <b-col cols="3" class="d-flex align-items-center p-0"
          ><span
            style="
              font-weight: 400;
              font-size: 14px;
              color: var(--elthThemeColor);
              white-space: nowrap;
            ">
            {{ translateCustomText("Products in your shop") }}:
            <span style="color: #8d2909"
              >{{
                selectedProductsArray?selectedProductsArray.length : 0
              }}/{{ this.products ? totalRows : 0 }}</span
            ></span
          ></b-col
        > -->
      <!-- <b-col cols="12" class="pl-0"
          >
          <div class="d-flex justify-content-between align-items-center">
            <div class="invisible">this is invisible div </div>
            <div
              class="float-right justify-content-center d-flex align-items-center w-100 h-100">
              <span
                ><input
                  type="radio"
                  class="mr-2"
                  v-model="filter_selection"
                  name="productSelection"
                  value="all"
                  id="" />{{ translateCustomText("All") }}</span
              >
              <span class="pl-3"
                ><input
                  type="radio"
                  v-model="filter_selection"
                  class="mr-2"
                  name="productSelection"
                  value="selected"
                  id="" />{{ translateCustomText("Selected") }}</span
              >
              <span class="pl-3"
                ><input
                  type="radio"
                  v-model="filter_selection"
                  class="mr-2"
                  name="productSelection"
                  value="notSelected"
                  id="" />{{ translateCustomText("Not selected") }}</span
              >
            </div>
               <span
            class="product-specs-heading text-center clear-btn"
            @click="resetFilter"
            >{{ translateCustomText("Reset filters") }}</span
          >
          </div>
          </b-col
        >
      </b-row>
      <b-row class="d-md-none">
        <b-col cols="5">
          <select v-model="filter_selection" class="control-input px-3 py-2">
            <option class="elth-select-option" value="all"
              ><label>{{
                translateCustomText("All")
              }}</label></option
            >
            <option class="elth-select-option" value="selected"
              ><label>{{
                translateCustomText("Selected")
              }}</label></option
            >
            <option class="elth-select-option" value="notSelected"
              ><label>{{
                translateCustomText("Not selected")
              }}</label></option
            >
          </select>
          <i class="fa fa-chevron-down"></i>
        </b-col>
        <b-col cols="7">
          <div class="d-flex align-items-center h-100 justify-content-end">
            <span
             class="product-specs-heading text-center clear-btn"
             @click="resetFilter"
             >{{ translateCustomText("Reset filters") }}</span
           >
          </div>
        </b-col>
      </b-row> -->
    </section>
    <!-- Show Table for sm md lg devices -->
    <b-row v-if="isLoading ||isLoadingTable">
      <b-col cols="12">
        <div class="table-loader">
          <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
        </div>
      </b-col>
    </b-row>
    <div class="" v-if="isLoadFirstTime">
      <div
        v-for="index in 4"
        :key="index"
        class="integrationImgae bg-preload my-3"
        style="height: 55px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
    </div>
    <div class="" v-else>
      <b-table
        :sticky-header="true"
        thead-class="table-header"
        :items="getProducts"
        :fields="translatedFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        head-variant="light"
        
        show-empty
        tbody-class="scrollable-tbody"
        @sort-changed="fetchData"
        :responsive="true"
        style="font-family: 'Montserrat'"
        class="d-none table m-0 table-container scrollable-tbody baldan-table d-sm-block"
        @row-clicked="clickableRow">
        <template #table-busy>
          <div class="text-center my-2" style="color: #8d2909">
            <b-spinner class="align-middle"></b-spinner>
            <!-- <strong>Loading...</strong> -->
          </div>
        </template>
        <template #head(checkbox) >
          <b-form-checkbox
            :id="`selectAll-${Date.now()}`"
            v-model="isSelectedAll"
            :name="`selectAll-${Date.now()}`"
            @change="selectAllProductsEvent($event)"
            style="cursor: pointer"
            size="lg"
            class="ProductItemCheckbox">
          </b-form-checkbox>
        </template>
        <template #cell(checkbox)="data">
          <div class="tooltip text-center" style="position: relative">
            <div
              class="elth-tooltip text-center"
              style="
                border-radius: 5px;
                background: rgba(156, 70, 41, 0.8);

                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              "
              v-if="filter_selection=='shop'">
              {{ translateCustomText("Remove from shop") }}
            </div>
            <div
              class="elth-tooltip"
              style="
                border-radius: 5px;
                background: rgba(80, 58, 60, 0.8);

                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              "
              v-else>
              {{ translateCustomText("Add to shop") }}
            </div>
          </div>
          <b-form-checkbox
            :id="`${data.item.id}-${Date.now()}`"
            v-model="selectedProductsArray"
            :name="`${data.item.id}-${Date.now()}`"
            :value="checkConvertIntegar(data.item.external_id)"
            @change="isNotRemoveCheck(checkConvertIntegar(data.item.external_id))"
            style="cursor: pointer"
            size="lg"
            :ref="'checkbox' + data.item.external_id"
            class="ProductItemCheckbox">
          </b-form-checkbox>
        </template>

        <template #cell(image)="data">
          <LazyImage
            :src="ImageSrc(data.item.image)"
            :key="`${data.index}-${data.item.id}`"
            style="
              width: 54px;
              height: 59px;
              border-radius: 12px;
              cursor: pointer;
            "
            alt="" />
        </template>
        <template #cell(title)="data">
          <a
            class="elth-title m-0"
            target="_blank"
            :href="data.item.detail_link"
            >{{ data.item.title }}
          </a>
        </template>
        <template #cell(price)="data">
          {{ getCurrency }} {{ data.item.price }}
        </template>
        <template #cell(vendor)="data">
          {{ data.item.vendor }}
        </template>
        <!-- v-if="quizId == baldanQuizID"  -->
        <template #cell(sku)="data" v-if="quizId == baldanQuizID">
          {{ data.item.sku }}
        </template>
        <!-- <template #cell(detail_link)="data">
          <b-button
            type="button"
            :href="data.item.detail_link"
            target="_blank"
            class="btn btn-elth">
            {{ translateCustomText("View") }}
          </b-button>
        </template> -->
        <template #empty="">
          <div class="" style="min-height: 170px">
            <h4 v-if="getProducts.length < 1" class="mt-5">
              {{ translateCustomText("Products not found.") }}
            </h4>
            <h4 v-else class="mt-5">
              {{
                translateCustomText(
                  "Unfortunately, no product matches the selected filter criteria."
                )
              }}
            </h4>
          </div>
        </template>
      </b-table>
      <!-- Show for smaller devices -->
      <div
        class="d-block d-sm-none pagination-bar py-2"
        style="
          color: #fff;

          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
        ">
        <div class="d-flex justify-content-between align-items-center px-4">
          <b-form-checkbox
            :id="`selectAll-${Date.now()}`"
            v-model="isSelectedAll"
            :name="`selectAll-${Date.now()}`"
            @change="selectAllProductsEvent($event)"
            style="cursor: pointer"
            size="lg"
            class="ProductItemCheckbox">
          </b-form-checkbox>
          <div class="w-100">Pick products here</div>
           <b-form-checkbox
            :id="`selectAll-${Date.now()}`"
            v-model="isSelectedAll"
               
            :name="`selectAll-${Date.now()}`"
            @change="selectAllProductsEvent($event)"
            style="cursor: pointer"
            size="lg"
            class="ProductItemCheckbox invisible">
          </b-form-checkbox>
        </div>
      </div>
      <div
        class="products-list d-block d-sm-none scrollable-tbody"
        style="max-height: 300px; overflow-y: auto">
        <div 
          class="product-item bg-white"
          v-for="(product, index) in getProducts"
          :key="index + product.external_id">
          <div class="d-flex justify-content-center align-items-center">
            <div style="flex: 0.1">
              <b-form-checkbox
              @change="isNotRemoveCheck(checkConvertIntegar(data.item.external_id))"
                :id="`${product.id}-${Date.now()}`"
                v-model="selectedProductsArray"
                :name="`${product.id}-${Date.now()}`"
                :value="checkConvertIntegar(product.external_id)"
                style="cursor: pointer"
                size="lg"
                :ref="'checkbox' + index"
                class="ProductItemCheckbox">
              </b-form-checkbox>
            </div>
            <div style="flex: 0.2; margin-right: 6px;">
              <LazyImage
                :src="ImageSrc(product.image)"
                :key="`${index}-${product.id}`"
                style="
                  width: 54px;
                  height: 59px;
                  border-radius: 12px;
                  cursor: pointer;
                "
                alt="" />
            </div>
            <div style="flex: 0.7">
              <div class="product-md-heading text-left">
                <a
                  class="elth-title m-0"
                  target="_blank"
                  :href="product.detail_link"
                  >{{ product.title }}
                </a>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <div class="">
                  <p class="mb-1 text-left" style="font-weight: 700; font-size: 12px">
                    {{ translateCustomText("Brand") }}
                  </p>
                  <div class="text-left product-md-heading" style="width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;">
                    {{ product.vendor }}
                  </div>
                </div>
                <div class="">
                  <p class="mb-1 text-left" style="font-weight: 700; font-size: 12px">
                    {{ translateCustomText("Price") }}
                  </p>
                  <div class="product-md-heading text-left">
                    {{ getCurrency }} {{ product.price }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-row class="justify-content-center" v-if="quizId == baldanQuizID">
            <b-col cols="4"> </b-col>
            <b-col cols="4"> </b-col>
            <b-col cols="4">
              <p class="mb-1" style="font-weight: 700; font-size: 12px">
                {{ translateCustomText("Code") }}
              </p>
              <div class="product-md-heading">
                {{ product.sku }}
              </div>
            </b-col>
          </b-row>
        </div>
          <div class="" style="min-height: 170px">
            <h4 v-if="getProducts.length < 1" class="mt-5">
              {{ translateCustomText("Products not found.") }}
            </h4>
           
          </div>
      </div>
    </div>
    <div class="pagination-bar p-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-md-between w-100">
          <div
            class="col-md-5 col-10 p-0  text-left"
            :class="[
              selectedProductsArray.length || selectFilter > 0 ? 'visible' : 'invisible',
            ]">
            <span style="color: #fff" v-if="!selectFilter"
              >{{ selectedProductsArray ? selectedProductsArray.length : 0 }}
              {{ translateCustomText("Selected") }}</span
            >
              <span v-else-if="selectFilter && !checkFilterIsEmpty" style="color: #fff" class="pl-2">
              {{ translateCustomText("All") }} {{  filter_selection=='shop'?getShopProductsCount: getShopProductsCount}}
              {{ translateCustomText("products are selected") }}
              <span @click="toggleSelectAllStatus()" class="select-all-span">{{
                translateCustomText("Undo")
              }}</span>
            </span>
          <span v-if="getPage.totalRows>25">
            <span
              v-if="!selectFilter&& checkFilterIsEmpty"
              class="select-all-span pl-2"
              @click="toggleSelectAllStatus()"
              >{{ selectFilter ? "translateCustomText('Undo')" : translateCustomText("Select all") }}
              {{ getPage.totalRows }} {{ translateCustomText("products") }}</span
            >
            <span v-else-if="selectFilter && checkFilterIsEmpty" style="color: #fff" class="pl-2">
              {{ translateCustomText("All") }} {{  filter_selection=='shop'?getShopProductsCount: getShopProductsCount }}
              {{ translateCustomText("products are selected") }}
              <span @click="toggleSelectAllStatus()" class="select-all-span">{{
                translateCustomText("Undo")
              }}</span>
            </span>
            </span>
            <span v-else class="invisible" style="color: #fff" >
              {{ translateCustomText("All") }} {{ filter_selection=='shop'?getShopProductsCount: getShopProductsCount}}
              {{ translateCustomText("products are selected") }}
             
            </span>
          </div>
          <div
            class="d-flex  p-0 col-2 justify-content-end justify-content-md-center"
            style="gap: 5px; flex: 1">
            <button
              :disabled="getPage.currentPage <= 1 "
              @click="
                () => {
                 
                  movePagination( -1,'back');
                }
              "
              class="pagination-btn">
              <b-icon icon="caret-left"></b-icon>
            </button>
            <button
              :disabled="
                !(getPage.currentPage  < Math.ceil(getPage.totalRows / perPage)) 
               
              "
              @click="
                () => {
                  movePagination(1,'next');
                }
              "
              class="pagination-btn">
              <b-icon icon="caret-right"></b-icon>
            </button>
          </div>
           <div

           class="d-none d-md-block col-md-5 p-0 col-0 invisible">        
            <span style="color: #fff" class="pl-2">
             
              <span  class="select-all-span">{{
                translateCustomText("Undo")
              }}</span>
            </span>
           
           
          </div>
          
        </div>
      </div>
    </div>
    <div
      
      class="text-center my-3"
      style="
        color: #503a3c;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
      ">
      {{ translateCustomText("Products in your shop") }}:
      <span style="font-weight: 600"
        >{{ shopProductCount }}/{{
          totalQuizellProducts
        }}
      </span>
    </div>
  </div>
</template>

<script>
import ElthButton from './ElthButton'
import ElthModal from './ElthModal'
import axios from "axios";
import { debounce } from "lodash";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import { mapGetters } from "vuex";
// import FilterSelectVue from '../../../Dashboard/Layout/FilterSelect.vue';

export default {
  mixins: [TranslationMixin],
  components: {
    ElthModal,
    ElthButton
  },
  props: {
    isCreate: Boolean,
    
    email: String,
    apiToken: String,
    selectedProducts: Array,
    loading: Boolean,
    quizId: Number,
    // isCreate:Boolean
  },
  watch: {
        notRemoveSelectAllArray:{
handler() {
  if(this.notRemoveSelectAllArray.length>0)
  {
    this.isNotRemoveSelectAllArray=true
  }
  else
  {
     this.isNotRemoveSelectAllArray=true
  }
   this.$emit('isNotRemoveProducts',{isNotRemoveSelectAllArray:this.isNotRemoveSelectAllArray,notRemoveSelectAllArray: this.notRemoveSelectAllArray})
        this.$emit("update", this.selectedProductsArray);
        this.$emit("payload", this.payload);
},
deep:true,
        },
    getProducts:{
      handler(newValue){
this.isSelectedAll = newValue.every((product) =>
            this.selectedProductsArray.includes(this.checkConvertIntegar(product.external_id))
          );
      },
      deep:true
    },
    selectedProductsArray: {
      handler(newValue) {
          this.isSelectedAll = this.getProducts.every((product) =>
            newValue.includes(this.checkConvertIntegar(product.external_id))
          );
        
        this.$emit('isNotRemoveProducts',{isNotRemoveSelectAllArray:this.isNotRemoveSelectAllArray,notRemoveSelectAllArray: this.notRemoveSelectAllArray})
        this.$emit("update", newValue);
        this.$emit("payload", this.payload);
      },
      deep: true,
    },
    filter_text() {
      this.currentPage = 1;
      this.handleProductSearchDebounced();
    },
    filter_selection(newValue){
      this.$emit('switch',newValue)
    },
   
  },
  computed: {
    checkFilterIsEmpty(){
     return this.selectedVendor==''&&this.selectedType==''&&this.filter_text==''
    },
    payload() {
      return {
        save_all_status: this.selectFilter,
        filter: {
          title: this.filter_text,
          produc_type: this.selectedType,
          vendor: this.selectedVendor,
          order_by: this.sortBy,
          order_value: this.sortDesc ? "desc" : "asc",
          option_ids: this.options,
        },
      };
    },
    translatedFields() {
      return this.fields.map((field) => {
        if (this.quizId != this.baldanQuizID && field.key == "sku") return;
        if (field.label !== "") {
          return {
            ...field,
            label: this.translateCustomText(field.label),
          };
        }
        return field;
      });
    },
    ...mapGetters(["getPreviewSettings", "getEnvConst"]),
    getQuizID() {
      return this.getPreviewSettings.quizId;
    },
    getEnv() {
      // getEnvConst
      return this.getEnvConst;
    },
    getCurrency() {
      return this.getPreviewSettings.currencySign.sign;
    },
 
    getSelectedProduct() {
      return this.selectedProductsData;
    },
   
    productOnSelection() {
      return this.getProducts;
    },
    baldanQuizID() {
      return this.getEnv?.VUE_APP_ITALIAN_QUIZID ?? null;
    },
    getVendorTypes(){
     return this.filter_selection=='shop'?{brand:this.liveProductVendors,type:this.liveProductType}:{brand:this.quizellProductVendors,type:this.quizellProductTypes}
    },
    getProducts(){
     return this.filter_selection=='shop'?this.liveProducts:this.products
    },
    getPage(){
     return  this.filter_selection=='shop'?{totalRows:this.liveRows,currentPage:this.liveCurrentPage}:{totalRows:this.quizellRows,currentPage:this.quizellCurrentPage}
    },
    isAllProductsSelected(){
      return   this.getPage.totalRows==this.selectedProductsArray.length
    },
     shopProductCount(){
         let count = 0;
   
    if(this.filter_selection=='shop')
{
      if(this.selectFilter)
      count = this.notRemoveSelectAllArray.length;
    else
      count= this.totalLiveProducts-this.selectedProductsArray.length
    }
       
    else
      {if(this.selectFilter)
      count = this.totalQuizellProducts-this.notRemoveSelectAllArray.length;
    else
      count= this.totalLiveProducts+this.selectedProductsArray.length
    }
  
    return count
    },
    getShopProductsCount(){
    let count = 0;
    if(this.filter_selection=='shop')
    {   
      if(this.selectFilter)
      count = (this.totalLiveProducts-this.notRemoveSelectAllArray.length);
    else
      count= this.totalQuizellProducts-this.selectedProductsArray.length
    }
    else
    {
     if(this.selectFilter)
     count = this.totalQuizellProducts-this.notRemoveSelectAllArray.length;
    else
      count= this.selectedProductsArray.length;
    }
   return count
  },
  },
  methods: {
  isNotRemoveCheck(externalId){
   if(this.selectFilter)
          {
            this.isNotRemoveSelectAllArray=true;
     
      const index = this.notRemoveSelectAllArray.findIndex((value)=>value==externalId)
     
            if(index>-1)
            {
              this.notRemoveSelectAllArray.splice(index,1)
            }
            else{
            this.notRemoveSelectAllArray.push(externalId)
            }
         
          }
         
          
          },
    checkToggleProduct(btn){
      this.modalButton=btn;
      if(this.selectedProductsArray.length>0)
      {
        this.$refs.elthModal.open();
      }
      else
      {
      this.toggleStoreToAddMore();
      }
   
    },
    toggleStoreToAddMore(){
      this.filter_selection=this.modalButton; 
      this.selectedProductsArray=[];
      this.isSelectedAll=false;
      this.selectFilter=false;
      this.isNotRemoveSelectAllArray=false;
      this.notRemoveSelectAllArray=[];
      this.selectedVendor='';
      this.selectedType='';
      this.filter_text='';
    },
    toggleSelectAllStatus() {
      
      this.selectFilter = !this.selectFilter;
      if(!this.selectFilter)
      {
          this.isNotRemoveSelectAllArray=false
          this.notRemoveSelectAllArray=[]
      }
      if(this.selectFilter)
      {
       this.selectedProductsArray = [
            ...new Set([
              ...this.selectedProductsArray,
              ...this.getProducts.map((product) =>
                this.checkConvertIntegar(product.external_id)
              ),
            ]),
          ];}
          else{
           this.selectedProductsArray = [];
          }
     
      this.$emit("payload", this.payload);
    },
    updateSelected(products) {
      this.totalSavedProduct = products;
    },
    filterMethod() {
     
          if(this.filter_selection=='shop')
      {
        this.liveCurrentPage=1;
        this.getQuizellProducts()
      }
      else
      {
       this.quizellCurrentPage=1;
      this.getCreateProducts();
    }
      
    },
    handleProductSearchDebounced: debounce(function () {
     this.filterMethod();
    }, 500),
    fetchData({ sortDesc, sortBy }) {
      this.currentPage = 1;
      this.sortDesc = sortDesc;
      this.sortBy = sortBy;

      this.getCreateProducts();
    },
    movePagination(value,page) {
      if(this.filter_selection=='shop')
      {
        this.liveCurrentPage+=value;
        this.getQuizellProducts(page)
      }
      else
      {
       this.quizellCurrentPage+=value;
      this.getCreateProducts(page);
    }
    },
  
    clickableRow(e) {
      
      const index = this.selectedProductsArray.findIndex(
        (selected) =>
          this.checkConvertIntegar(e.external_id) ==
          this.checkConvertIntegar(selected)
      );

      if (index > -1) {
        this.selectedProductsArray.splice(index, 1); // Remove the row
      } else {
        this.selectedProductsArray.push(
          this.checkConvertIntegar(e.external_id)
        ); // Add the row
      }
      this.isNotRemoveCheck( this.checkConvertIntegar(e.external_id))
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.getQuizID);
    },
    openBaldenProducts(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
    isChecked(external_id) {
      return this.selectedProductsData.includes(
        this.checkConvertIntegar(external_id)
      );
    },
    async selectAllProductsEvent(e) {
      if (e) {
        try {
        if(this.selectFilter)
        {
      this.getProducts.forEach(product=>{
    const index = this.notRemoveSelectAllArray.findIndex((value)=>value==product.external_id)
     
            if(index>-1)
            {
              this.notRemoveSelectAllArray.splice(index,1)
            }
           
    })
      }
          this.selectedProductsArray = [
            ...new Set([
              ...this.selectedProductsArray,
              ...this.productOnSelection.map((product) =>
                this.checkConvertIntegar(product.external_id)
              ),
            ]),
          ];
        } catch (error) {
          console.log(error);
          if (error) {
            this.$toasted.show(
              this.translateCustomText(`An error has occurred`),
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
          }
        }
      } else {
       if(this.selectFilter)
        {
        let newNotRemoveSelectAllArray =[]
               this.getProducts.forEach(product=>{
              const index = this.notRemoveSelectAllArray.findIndex((value)=>value==this.checkConvertIntegar(product.external_id))
            if(index<=-1)
            {
              newNotRemoveSelectAllArray.push(this.checkConvertIntegar(product.external_id))
            }
           
    })

         this.notRemoveSelectAllArray = [...new Set([...newNotRemoveSelectAllArray, ...this.notRemoveSelectAllArray])];
         }
     
       this.selectedProductsArray = []
      }
     
    },
    spliceByIDs() {
      this.products.forEach((obj) => {
        const index = this.selectedProductsArray.findIndex(
          (item) => item == obj.external_id
        );
        if (index != -1) {
          this.selectedProductsArray.splice(index, 1);
        }
      });
    },
    resetFilter() {
      // this.selectAllProducts = false;
      this.selectedVendor = "";
      this.selectedType = "";
      this.filter_text = "";
    
      this.filterMethod();
    },
    toggleSelectedProduct(event, id) {
      if (["TD"].includes(event.target.tagName))
        this.$refs["checkbox" + id][0].$refs.input.click();
    },
    toggleSingleProduct() {
      this.isSelectedAll =
        this.productOnSelection.every((e) =>
          this.getSelectedProduct.includes(
            this.checkConvertIntegar(e.external_id)
          )
        ) && this.productOnSelection.length > 0;
    },
    checkConvertIntegar(value) {
      const parsedValue = parseInt(value, 10);
      if (Number.isNaN(parsedValue)) {
        return value;
      }
      return parsedValue;
    },
    ImageSrc(img) {
      if (img) return img;
      return 'https://images.quizell.com/website/default_start_page.png';
    },
      async getQuizellProducts(){
      
         const payload = {
        api_token: this.apiToken,
        email: this.email,
        title: this.filter_text,
        produc_type: this.selectedType,
        vendor: this.selectedVendor,
        order_by: this.sortBy,
        order_value: this.sortDesc ? "desc" : "asc",
        
        page: this.liveCurrentPage,
      }
       this.isLoading=true;
      this.isLoadingTable = true;
          const response = await this.axiosMethod(
        "post",
        "/api/custom/collection/filter/live/products",payload
      );
      
      if(response.status)
      {
        this.liveProducts=response.data.data.data;
        this.liveRows = response.data.data.total;
         if(this.selectFilter)
        {
        
        if(this.filter_selection=='shop')
        {
        this.isSelectedAll =true;
        
          this.selectedProductsArray =[... new Set([...this.selectedProductsArray, ...this.liveProducts.map(product => this.checkConvertIntegar(product.external_id))])];
          this.selectedProductsArray =  this.selectedProductsArray.filter(product=>!this.notRemoveSelectAllArray.includes(product));
         
        }
         
        }
        else{
          this.isSelectedAll =false;
        }
         if(this.isLoadFirstTime)
        {
        this.totalLiveProducts = this.liveRows;
        }
        this.liveCurrentPage = response.data.data.current_page;
      }
       this.isLoading=false;
      this.isLoadingTable = false;
    },
    async getCreateProducts() {
     
      const data = {
        api_token: this.apiToken,
        email: this.email,
        title: this.filter_text,
        produc_type: this.selectedType,
        vendor: this.selectedVendor,
        order_by: this.sortBy,
        order_value: this.sortDesc ? "desc" : "asc",
       option_ids: this.options,
        page: this.quizellCurrentPage,
      };
      this.isLoading=true;
      this.isLoadingTable = true;
      try {
        const response = await axios.post(
          "/api/custom/collection/filter/available/products",
          data
        );
         
        this.products = response.data.data.data;
         if(this.selectFilter&&this.filter_selection=='add more')
        {
         this.isSelectedAll =true;
         this.selectedProductsArray =[... new Set([...this.selectedProductsArray, ...this.products.map(product => this.checkConvertIntegar(product.external_id))])];
          this.selectedProductsArray =  this.selectedProductsArray.filter(product=>!this.notRemoveSelectAllArray.includes(product));
        }
         else{
          this.isSelectedAll =false;
        }
        this.quizellRows = response.data.data.total;
       
        this.quizellCurrentPage = response.data.data.current_page;
      } catch (error) {
        console.error(error);
      }
      this.isLoadingTable = false;
       this.isLoading=false;
    },
     async axiosMethod(method, url, payload='') {
      return await axios[method](url, payload)
        .then((response) => response)
        .catch((error) => {
          console.log(error);
          this.$toasted.show(
            this.translateCustomText(`An error has occurred`),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
          return false;
        });
    },
  
  async getQuizellProductsFilter(){
    const payload = {
    email: this.email,
    api_token: this.apiToken,
    }
      const response = await this.axiosMethod(
        "post",
        "/api/custom/collection/filter/data",payload
      );
      if(response.status)
      {
         
          this.liveProductVendors=response.data.data.live.brands;
          this.liveProductType=response.data.data.live.types;
          this.quizellProductVendors = response.data.data.available.brands;
          this.quizellProductTypes = response.data.data.available.types;
          this.options = response.data.data.option_ids;
           this.totalQuizellProducts = response.data.data.total_available_products;
      }

    },
    async getProductsTypeBrand() {
      const data = {
        api_token: this.apiToken,
        email:this.email

      };

      this.isLoadingTable = true;
      await axios
        .post("/api/custom/collection/filter/available/brands", data)
        .then((resp) => {
          this.quizellProductVendors = resp.data.data.brands;
          this.quizellProductTypes = resp.data.data.types;
        })
        .catch((error) => {
          console.log(error);
        });
      this.isLoadingTable = false;
    },
  
  },

  async mounted() {
   this.isSelectedAll=false;
    
   this.filter_selection='shop'
   this.$emit('switch',this.filter_selection)
    await this.getQuizellProductsFilter();
    (this.totalSavedProduct = [...this.selectedProducts]),
      await this.getCreateProducts();
       
       await this.getQuizellProducts();
    this.isLoadFirstTime = false;

    
    this.$emit("loaded");
  },
  data() {
    return {
      all_selected_status:false,
      all_shop_product_count:0,
      isNotRemoveSelectAllArray:false,
      notRemoveSelectAllArray:[],
      modalButton:null,
      options: [],
       totalQuizellProducts:0,
      totalLiveProducts:0,
      totalStoredProducts: 0,
      totalSavedProduct: [],
      selectFilter: false,
      selectedProductsArray:[],
      isLoadFirstTime: true,
      isLoadingTable: false,
      currentPage: 1,
      isLoading:false,
      totalRows: 1,
      quizellRows:1,
      quizellCurrentPage:1,
      liveRows:1,
      liveCurrentPage:1,
      perPage: 25,
      products: [],
      sortBy: "title",
      sortDesc: false,
      fields: [
        {
          key: "checkbox",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "image",
          label: "Image",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
          thClass: "max-width-300 text-nowrap",
          tdClass: "max-width-",
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
          thClass: "max-width-150 text-nowrap",
          tdClass: "max-width-150",
        },
        {
          key: "vendor",
          label: "Brand",
          sortable: true,
          thClass: "text-nowrap",
        },
        {
          key: "sku",
          label: "Code",
          tdClass: "text-center",
          sortable: true,
          thClass: "text-nowrap",
        },
        // {
        //   key: "detail_link",
        //   label: "Product page",
        //   thClass: "max-width-150 text-nowrap",
        //   tdClass: "max-width-150",
        // },
      ],

      scripts: [],
      selectedProductsData: this.selectedProducts,
      filter_selection: "shop",
      filter_text: "",
      liveProductVendors: [],
      liveProductType: [],
      
      quizellProductVendors:[],
      quizellProductTypes:[],
      liveProducts:[],
      selectedType: "",
      isSelectedAll: false,
      selectedVendor: "",
   


      sorting: {
        column: null,
        isNumeric: false,
        order: "asc",
      },
      // Store previous clicked header to reset its state
      prevClickedHeader: null,
      imageHeader: "",
    };
  
  },
};
</script>
<style>
.elth-title {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.elth-title:hover {
  color: inherit;
}
tbody tr {
  background-color: #fff;
  color: var(--elthThemeColor);
}
.table-header {
  background: #503a3c;
  color: #fff;
}
.table-container {
  height: 300px;
  overflow-y: auto;
}
.max-width-400 {
  width: 400px;
}
.max-width-150 {
  width: 200px;
}
@supports (position: sticky) {
  .table.b-table > thead > tr > th {
    background: #503a3c;
    color: white;
    font-weight: 600;
  }
}
</style>
<style scoped>
* {
  --elthThemeColor: #503a3c;
}
.select-all-span {
  color: rgba(0, 255, 240, 1);
  cursor: pointer;
}
.select-all-span:hover {
  text-decoration: underline;
}
.pagination-bar {
  background: #503a3c;
  border-radius: 1px;
}
.pagination-bar:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.pagination-btn {
  border-radius: 5px;
  /* */
  background: #fff;
  border: 1px solid rgba(80, 58, 60, 1);
  color: rgba(80, 58, 60, 1);
}
.pagination-btn:hover {
  background: #e1e0e0;
}
active {
  box-shadow: 2px 2px 5px #e1e0e0;
}
.pagination-btn:disabled {
  background: rgb(198, 196, 196);
  cursor: not-allowed;
}
.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
}
.table-container .b-table tbody tr {
  background-color: #fff;
  color: var(--elthThemeColor);
}
select {
  padding-right: 30px;
}
.btn-elth {
  border: 1px solid #503a3c;
  border-radius: 5px;
  transition: 0.3s ease all;
  color: #503a3c;
  background: transparent !important;
}
.btn-elth:hover {
  background: #503a3c !important;
  color: #ffffff;
}
.ProductItemCheckbox >>> .custom-control-input:checked ~ label::before {
  background: #a47c6e !important;
}

.ProductItemCheckbox
  >>> .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: none;
  border-color: #bdc3ca!important;
}
.table td {
  text-align: center;
}
.table-container >>> table tbody > tr > td {
  vertical-align: middle;
}
.scrollable-tbody::-webkit-scrollbar {
  width: 22px;
  background: #503a3c !important;
  /* background:white; */
}

.scrollable-tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border: 1px solid var(--elthThemeColor);
  background-clip: padding-box;
  background: #fff !important;
  margin-top: 55px;
}

.scrollable-tbody::-webkit-scrollbar-thumb {
  background-color: var(--elthThemeColor);
  outline: 1px solid var(--elthThemeColor);
  background-image: linear-gradient(white, white), linear-gradient(white, white),
    linear-gradient(white, white);
  background-position: 50% 38%, 50% 50%, 50% 65%;
  background-repeat: no-repeat;
  background-size: 50% 1px, 50% 1px, 50% 1px;
}

/* .scrollable-tbody::-webkit-scrollbar-button {
  border: 1px solid var(--elthThemeColor);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 22px;
  width: 1em;
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
} */

/* .scrollable-tbody::-webkit-scrollbar-button:horizontal:increment {
  background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
}

.scrollable-tbody::-webkit-scrollbar-button:end:increment {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.scrollable-tbody::-webkit-scrollbar-button:start:decrement {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
} */
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
th {
  cursor: pointer;
  font-weight: 600;
}
.elth-tooltip {
  position: absolute;
  min-width: 184px;
  height: 27px;
  left: 20px;
  top: -30px;
  border-radius: 5px;
  color: #ffff;
  font-size: 14px;
  line-height: 2;
}
td .tooltip {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
td:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.product-item {
  padding: 8px 20px;
  /* min-height: 120px; */
  border-bottom-color: #cac7c7;
  border-bottom-style: solid;
  border-bottom-width: 1px;

  border-left-color: var(--elthThemeColor);
  border-left-style: solid;
  border-left-width: 1px;

  border-right-color: var(--elthThemeColor);
  border-right-style: solid;
  border-right-width: 1px;
  color: var(--elthThemeColor);
}
.modal-button{
  border-radius: 30px!important;
    border: 1px solid #2A1C1E!important;
    min-width: 116px !important;
    font-size: 18px !important;
}
.product-specs-heading {
  font-weight: 700;
  font-size: 16px;
  overflow-wrap: anywhere;
}
.product-md-heading {
  color: #503a3c;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.elth-input-control {
  border: 1px solid var(--elthThemeColor);
  font-size: 14px;
}
.elth-input-control:focus {
  border-color: var(--elthThemeColor);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(80, 58, 60, 0.25);
}
.clear-btn {
  cursor: pointer;
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  transition: 0.5s ease all;
  color: #8d2909;
}
.clear-btn:hover {
  text-decoration: underline;
}
.select-all {
  width: 100%;
  min-height: 41px;
  background: rgba(80, 58, 60, 0.08);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
span input[type="radio"] {
  height: 16px;
  width: 16px;
  vertical-align: middle;
  accent-color: var(--elthThemeColor);
}
.modal-heading{
  color: #000;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
/* line-height: 20px; 100% */
}
.modal-body{
 color: #503A3C;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
}
.elth-header {
  color: #503a3c;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 100% */
  text-transform: uppercase;
}
.asc:after {
  content: " Ã¢â€“Â²";
}
.desc:after {
  content: " Ã¢â€“Â¼";
}
.elth-description {
  font-weight: 400;
  font-size: 14px;
}
.table-loader {
  position: absolute;
  width: 100%;
  height: 350px;
  /* top: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 40;
}
.baldan-table >>> .b-table thead tr > [aria-sort="none"],
.baldan-table .b-table tfoot tr > [aria-sort="none"],
.table-header tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' viewBox='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white'  d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

.baldan-table >>> .b-table thead tr > [aria-sort="ascending"],
.baldan-table .b-table tfoot tr > [aria-sort="ascending"],
.table-header tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='SaddleBrown' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='SaddleBrown' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

.baldan-table >>> .b-table thead tr > [aria-sort="descending"],
.baldan-table .b-table tfoot tr > [aria-sort="descending"],
.table-header tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='SaddleBrown' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='SaddleBrown' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}
.customPagination >>> li > button {
  color: #503a3c;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #503a3c !important;
  border-color: #503a3c;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #9c4629 !important;
  border-color: #9c4629;
}
@media (max-width: 767px) {
  .select-all {
    width: 319px !important;
  }
  .scrollable-tbody::-webkit-scrollbar-track {
    margin-top: 0px;
  }
  .elth-header {
    font-size: 22px;
    text-align: center;
  }
}
.control-input {
  border-radius: 15px;
  border: 1px solid #503a3c;
  /* max-width: 604px; */
  width: 100%;
  background: #fff;
}
select {
  color: #503a3c;
}
.control-input {
  border-radius: 15px;
  border: 1px solid #503a3c;
  width: 100%;
  background: #fff;
}

/* Mozilla Firefox */
@-moz-document url-prefix() {
  .control-input {
    border-radius: 15px;
    border: 1px solid #503a3c;
    width: 100%;
  }
}

/* iOS devices */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .control-input {
    border-radius: 15px;
    border: 1px solid #503a3c;
    width: 100%;
  }
}

.control-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /* no standardized syntax available, no ie-friendly solution available */
}
.control-input + i.fa {
  float: right;
  margin-top: -27px;
  font-size: 14px;
  margin-right: 5px;
  display: block;
  /* z-index: 111111; */
  position: relative;
  /* this is so when you click on the chevron, your click actually goes on the dropdown menu */
  pointer-events: none;
  /* everything after this is just to cover up the original arrow */
  /* (for browsers that don't support the syntax used above) */
  background-color: #fff;
  padding-right: 5px;
}
.elt-btn {
  /* background: #503A3C; */
  color: #fff;
  border-radius: 15px;
  min-width: 119px;
  min-width: 168px;
  color: #9c4629;
  border: 1px solid #9c4629;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.border-wrapper {
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
}

.gradient-border {
 
    /* border: 2px solid #FF663A; */
   
    background-image: linear-gradient( rgb(156, 70, 41) 0%, rgb(204, 85, 49) 48%, rgb(255, 102, 58) 100%);
    border-image-slice: 1;
    border-radius: 30px;
    /* border-radius: 50em; */
  /* border: 4px solid transparent; */
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

    overflow: hidden;
    /* box-shadow: 0 0 0 2px #fff; Match the border width */
}
.gradient-border button{
 color: #503A3C;
 background:transparent;
 font-size:18px;
 font-weight:500;
 outline: none;
border: none;
border-radius:30px;
margin:1px;
}
button.active{
  background: #2A1C1E;
  color:#fff;
}
</style>